@import "src/scss/variables";

.our-service{
  padding: 128px 0 70px;

  .service-box{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .our-service{
    padding: 80px 0 60px;
    .service-box{
      margin-top: 40px;
      justify-content: left;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .our-service{
    padding: 93px 0 45.5px;
    .service-box{
      margin-top: 24px;
      flex-direction: column;
      gap: 40px;
    }
  }
}