@import "../../scss/variables";

.dev-stages {
  padding-top: 70px;
  padding-bottom: 70px;

  .stages_box {
    overflow: hidden;
    padding: 98px 0 23px;
    width: 100%;
    background-color: $black;
    border-radius: 64px;
  }

  .last_line {
    position: absolute;
    top: 12px;
    left: 63%;
    background-color: $primaryGreen;
  }

  .stages__items {
    padding: 0 33px;
    display: flex;
    align-items: flex-start;
    gap: 3%;
  }

  .circle {
    width: 26px;
    height: 28px;
  }

  .stage {
    position: relative;
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__num {
      margin-top: 46px;
      font-weight: 800;
      font-size: 32px;
      line-height: 39.14px;
      color: rgba(255, 255, 255, 0.58);
    }

    &__title {
      margin: 47px 0 28px;
      font-weight: 600;
      font-size: 28px;
      line-height: 33.6px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
@media only screen and (max-width: 1230px) {
  .dev-stages {
    > div {
      padding: 0;
    }
    .stages_box {
      border-radius: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .dev-stages {
    padding-top: 60px;
    padding-bottom: 50px;

    .stages_box {
      padding-top: 77.4px;
    }

    .circle {
      width: 20px;
      height: 21px;
    }

    .stage {
      &__num {
        margin-top: 35px;
        font-size: 24px;
        line-height: 29.35px;
      }

      &__title {
        margin: 22.2px 0 18.8px;
        font-size: 22.04px;
        line-height: 26.45px;
      }

      &__subtitle {
        font-size: 16px;
        line-height: 25.19px;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .dev-stages .container {
    max-width: 1320px;
    padding: 0;
  }
  .dev-stages {
    padding-top: 45.5px;
    padding-bottom: 40px;
    .stage__num {
      margin-top: 0;
    }

    .circle {
      width: 17px;
      height: 17px;
    }

    .green-line {
      background-color: $primaryGreen;
    }

    .stages_box_mob {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      .stage-item {
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;

        &__text {
          width: 41%;
        }

        &__svg {
          top: 0;
          position: absolute;
          height: 100%;
          left: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .circle {
            box-sizing: content-box;
            padding: 8px 0;
          }
        }
      }
      & > :nth-child(2) {
        margin-top: -18%;
      }
      & > :nth-child(3) {
        margin-top: -10%;
      }
      & > :nth-child(4) {
        margin-top: -18%;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .dev-stages .stage__num {
    font-size: 20px;
    line-height: 24px;
  }
  .dev-stages .stage__title {
    margin: 8px 0 11px;
    font-size: 16px;
    line-height: 19.57px;
  }
  .dev-stages .stage__subtitle {
    font-size: 14px;
    line-height: 17.2px;
  }
}

@media only screen and (max-width: 430px) {
  .dev-stages {
    .stages_box_mob {
      & > :nth-child(2) {
        margin-top: -28%;
      }
      & > :nth-child(3) {
        margin-top: -25%;
      }
      & > :nth-child(4) {
        margin-top: -30%;
      }
    }
  }
}
