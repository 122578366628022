@import "../../scss/variables";

.header {
  position: fixed;
  top: 0;
  padding: 15px 0;
  width: 100%;
  background-color: $background;
  z-index: 20;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .header__decktop__en {
      justify-content: end;
      gap: 120px;
    }
    .header__decktop__kg {
      .nav__link {
        font-size: 15px;
      }
    }
  }

  &__contact {
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
    color: $primaryGreen;
    font-family: Commissioner;
    font-style: normal;
    font-weight: 500;
    padding: 10px;
    border: none;
    border-radius: 6px;
    border: 1px solid $primaryGreen;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__decktop {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 60px;
    max-width: 100%;
  }

  .nav .active {
    font-weight: 700;
    color: $white;
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16.5px;

    &__link {
      font-size: inherit;
      color: $grayWhite;

      cursor: pointer;
      padding: 5px 10px;
      transition: all 0.15s ease-in;

      &:first-letter {
        text-transform: uppercase;
      }

      &:hover {
        font-weight: 700;
        color: $white;
      }
    }
  }

  .translate {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;
    color: $white;
    cursor: pointer;
    transition: all 0.15s ease-in;

    &__box {
      background-color: $background;
      border-radius: 8px;
      border: 0;
      color: $white;

      .select-options {
        background-color: $background;
      }
    }

    &__popup {
      position: absolute;
      background: $background;
      top: 40px;
      border-radius: 8px;
      transform: translateX(-35%);
      z-index: 1;
    }

    &__item {
      text-transform: capitalize;
      padding: 8px 25px;
      color: $grayWhite;
      transition: color 0.1s ease;

      &:hover {
        color: $white;
      }
    }

    &__arrow {
      width: 10px;
      height: 10px;
      color: $white;
      transition: all 0.1s ease;
    }
  }

  .hamburger-lines {
    height: 26px;
    width: 32px;
    z-index: 2;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: $white;
    }

    .line1 {
      transform-origin: 0 0;
      transition: transform 0.4s ease-in-out;
    }

    .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .line3 {
      transform-origin: 0 100%;
      transition: transform 0.4s ease-in-out;
    }
  }

  .menu-items {
    position: fixed;
    display: none;
    width: 100%;
    height: 100vh;
    top: 70px;
    background-color: $black;
    transform: translate(150%);
    transition: transform 0.5s ease-in-out;
    .header__mobile {
      display: none;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header {
    .nav {
      font-size: 18px;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .header {
    .header__callMe {
      display: none;
    }

    .header__decktop .nav,
    .header__contact {
      display: none;
    }
    .translate {
      position: absolute;
      right: 50px;
    }

    .menu-items {
      display: flex;
      justify-content: center;
      align-items: center;
      .header__mobile {
        display: block;
        .header__info > .nav {
          display: flex;
          flex-direction: column;
        }
        .header__info > .translate {
          display: none;
        }
        .header__callMe,
        .header__contact {
          display: block;
          margin: 0 auto;
        }
      }
    }

    .translate {
      flex: 1;
      justify-content: right;
      margin-right: 20px;
    }

    .translate__popup {
      transform: translateX(10px);
    }

    .hamburger-lines {
      display: flex !important;
    }

    .nav__link {
      font-size: 28px;
    }

    &__contact {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 15px 0;

    &__logo {
      width: 68px;
      height: 35px;
    }
    .nav__link {
      font-size: 24px;
      line-height: 24px;
    }

    &__contact {
      font-size: 14px;
    }

    .hamburger-lines {
      width: 26px;
      height: 20px;
    }

    .translate {
      font-size: 14px;
    }

    .menu-items {
      height: 93%;
      top: 63px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .header {
    .translate {
      right: 20px;
    }
    &__inner {
      .hamburger-lines {
        width: 25px;
        height: 20px;
      }
    }
  }
}
