@import "../../scss/variables";

.footer {
  background-color: $black;
  padding-top: 48px;
  padding-bottom: 74px;
  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  &__text {
    width: 140px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .footer__logo {
      width: 140px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      margin: 18px 0 30px;
      line-height: 23.2px;
      color: $grayWhite;
    }
  }
  &__links {
    display: flex;
    gap: 75px;
    width: 70%;
    padding: 20px 0 0 0;
    justify-content: space-between;
    h3 {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: normal;
      font-weight: 500;
    }
    .data_imgs {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 5px 0px;
      > a {
        color: $grayWhite;
        font-family: "Commissioner", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .number {
      a {
        width: 150px;
      }
    }
  }
  &__social {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .contact-inner {
    width: 280px;
    div {
      display: flex;
      flex-direction: column;
    }
    a {
      word-wrap: break-word;
      display: block;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      color: $grayWhite;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .footer__inner {
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-inner {
    a {
      width: 150px;
    }
  }
}

@media only screen and (max-width: 970px) {
  .footer__links {
    padding: 0 50px 0 0;
    flex-wrap: wrap;
    justify-content: end;
    gap: 60px;
    .contact-inner {
      width: 180px;
      order: 2;
    }
    .number {
      order: 1;
    }
    .email {
      order: 3;
      width: 180px;
    }
  }
}

@media only screen and (max-width: 708px) {
  .footer {
    text-align: center;
    &__inner {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    &__social {
      justify-content: center;
    }
    &__links {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 0;
      .contact-inner {
        a {
          margin: 0 auto;
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .footer__inner {
    text-align: left;
    display: block;
    .footer__text {
      margin: 0 0 40px 0;
    }
    .footer__social {
      justify-content: start;
    }
  }
  .footer__links {
    display: block;
    > div {
      margin: 0 0 40px 0;
    }
    .contact-inner {
      a {
        margin: 0;
      }
    }
  }
}
