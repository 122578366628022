@import "../../scss/variables";

.portfolio {
  padding-top: 50px;
  position: relative;
  height: 900px;
  .swiper-slide {
    border-radius: 35px;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    border-radius: 35px;
  }

  .container.portfolio-cont {
    display: none;
  }

  .portfolio-card {
    margin: 0 auto;
    width: 100%;
    padding: 50px 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 35px;
    cursor: pointer;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0) -14.31%,
      rgba(0, 0, 0, 0.412127) 25.58%,
      rgba(0, 0, 0, 0.81) 88.32%
    );
    .card-text {
      width: 65%;
      &__title {
        font-weight: 600;
        font-size: 44px;
        line-height: 48px;
      }

      &__subtitle {
        font-size: 18px;
        line-height: 32px;
        margin: 17px 0 25px;
        height: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__link {
        display: inline-block;
        padding: 16px 34px;
        border: 1px solid $primaryGreen;
        border-radius: 122px;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      &__noneBtn {
        height: 40px;
        width: 50px;
      }
    }

    .card-img {
      width: 25%;
      height: 100%;
    }
  }

  .arrow {
    position: absolute;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    z-index: 2;
    bottom: 430px;
  }

  .arrow.right {
    right: 18%;
  }
  .arrow.left {
    left: 18%;
  }

  .swiper-pagination {
    position: static;
    padding: 50px 0 0 0;
    z-index: 20;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: $background2;
  }

  .swiper-pagination-bullet-active {
    background-color: #939393;
  }
}

@media only screen and (max-width: 1200px) {
  .portfolio {
    .portfolio-card {
      padding: 40px 40px 25px;
      .card-text {
        width: 70%;

        &__title {
          font-size: 32px;
          line-height: 39.26px;
        }

        &__subtitle {
          font-size: 16px;
          margin: 15px 0 22px;
        }

        &__link {
          font-size: 14px;
          line-height: 17.12px;
          padding: 13px 25px;
        }
      }

      .card-img {
        width: 27%;
      }
    }
    .arrow {
      bottom: 460px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .portfolio {
    .arrow {
      bottom: 485px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .portfolio {
    height: 700px;
    .arrow {
      bottom: 285px;
    }
  }
}

@media only screen and (max-width: 950px) {
  .portfolio {
    height: 700px;
    .arrow.right {
      right: 6.5%;
    }
    .arrow.left {
      left: 6.5%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .portfolio {
    height: 700px;
    .swiper {
      .swiper-pagination {
        display: block;
      }

      .swiper-pagination-bullet {
        opacity: 1;
        background-color: $background2;
      }

      .swiper-pagination-bullet-active {
        background-color: #939393;
      }

      padding: 0 15px;
    }
    .arrow {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  .portfolio {
    height: 600px;
    .swiper-pagination {
      margin-top: 18px;
      position: static;
    }

    .portfolio-card {
      border-radius: 10px;
      padding: 25px;
      .card-text {
        width: 100%;
        &__title {
          font-size: 16px;
        }
        &__subtitle {
          font-size: 15px;
          margin: 5px 0 18px 0;
          line-height: 22px;
          height: 245px;
        }
        &__link {
          padding: 8px 20px;
        }
      }

      .card-img {
        align-self: flex-start;
      }
    }
  }
}
