@import "../../scss/variables";
.comments {
  margin: 60px 0px;
  background: $black;
  padding: 20px 100px;
  &__inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    &__shadowLeft {
      width: 100px;
      background: $black;
      min-height: 100%;
      left: -100px;
      position: absolute;
      z-index: 10;
      box-shadow: 60px 0px 70px $black;
    }
    &__shadowRight {
      width: 100px;
      background: $black;
      min-height: 100%;
      right: -100px;
      position: absolute;
      z-index: 10;
      box-shadow: -60px 0px 70px $black;
    }
  }
  .swiper-pagination {
    display: none;
  }

  .swiper {
    padding-top: 60px;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    border-radius: 32px;
  }

  &__background {
    padding: 50px 0 95px;
    background-color: $black;
    max-width: 100%;
    .arrow {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $white;
      cursor: pointer;
      z-index: 2;
      bottom: 35%;
    }

    .arrow.right {
      right: 26%;
    }
    .arrow.left {
      left: 26%;
    }
  }

  &__card {
    position: relative;
    padding: 80px 30px 40px;
    text-align: center;
    border-radius: 32px;
    background: $background2;
    cursor: pointer;
    .card-title {
      font-size: 28px;
      line-height: 33.6px;
    }

    .card-subtitle {
      margin-top: 3px;
      margin-bottom: 26px;
      font-size: 16px;
      line-height: 19.2px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    .card-paragraph {
      line-height: 19.2px;
      color: $grayWhite;
      height: 176px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .comments__inner__shadowLeft,
  .comments__inner__shadowRight {
    display: none;
  }
  .comments {
    padding: 60px 0px;
  }
}

@media only screen and (max-width: 950px) {
  .comments {
    &__inner {
      .arrow.right {
        right: 18% !important;
      }
      .arrow.left {
        left: 18% !important;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .comments {
    padding-top: 55px;
    padding-bottom: 55px;

    .swiper {
      padding: 60px 75px 0;
    }

    &__background {
      padding: 40px 0 25px;
      .arrow {
        display: none;
      }
    }

    .swiper-pagination {
      position: static;
      margin-top: 24px;
      display: block;
    }

    &__card {
      .card-title {
        font-size: 32px;
        line-height: 38.4px;
      }

      .card-subtitle {
        margin: 12px 0 19px;
        font-size: 20px;
        line-height: 24px;
      }

      .card-paragraph {
        font-size: 18px;
        line-height: 21.6px;
      }
    }
  }
}

@media only screen and (max-width: 666px) {
  .card-paragraph {
    height: 250px !important;
  }
}

@media only screen and (max-width: 520px) {
  .comments {
    .swiper {
      padding: 60px 16px 0;
    }

    &__card {
      .card-title {
        font-size: 20px;
        line-height: 24px;
      }

      .card-subtitle {
        margin: 12px 0 19px;
        font-size: 19.2px;
        line-height: 24px;
      }

      .card-paragraph {
        font-size: 16px;
        line-height: 19.2px;
        height: 250px !important;
      }
    }
  }
}
