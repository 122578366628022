@import "../../scss/variables";

.our-team {
  padding-top: 70px;
  padding-bottom: 90px;

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    border-radius: 24px;
  }

  .swiper-pagination {
    margin-top: 25px;
    position: static;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: $background2;
  }

  .swiper-pagination-bullet-active {
    background-color: #939393;
  }
  .swiper-card {
    border-radius: 24px;
    // background-color: white;
  }

  .team-card {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    height: 380px;
    padding: 0 10px 16px;
    text-align: center;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: white;
    border-radius: 24px;
    cursor: pointer;
    // width: 200px;
    // margin: 0 auto;

    &__title {
      font-size: 22px;
      line-height: 28.8px;
    }

    &__subtitle {
      color: $grayWhite;
      margin-right: 8px;
      font-size: 18px;
      line-height: 21.6px;
    }
  }

  .swiper-inner {
    position: relative;
    padding: 20px 0px 20px;
    z-index: 10;

    .arrow {
      position: absolute;
      top: 46%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      display: none;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $white;
      cursor: pointer;
      z-index: 2;
    }

    .arrow.right {
      left: 100%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .our-team {
    padding-top: 60px;
    padding-bottom: 100px;

    .team-card {
      &__title {
        font-size: 19px;
        line-height: 24px;
      }

      &__subtitle {
        margin-top: 5px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .our-team {
    .team-card {
      height: 360px;
    }
  }
}

@media only screen and (min-width: 840px) {
  .our-team {
    .swiper-inner {
      .arrow {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .our-team {
    padding-top: 40px;
    .swiper-pagination-bullet {
      display: none;
    }
  }
}

@media only screen and (max-width: 560px) {
  .our-team {
    .team-card {
      max-height: 350px;
    }
  }
}
@media only screen and (max-width: 360px) {
  .our-team {
    .team-card {
      max-height: 420px;
    }
  }
}
