@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src: url("./fonts/Commissioner-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}