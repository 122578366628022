@import "../../scss/variables";

.about-us {
  padding-top: 70px;
  padding-bottom: 70px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    width: 43%;
    font-weight: 500;
    font-size: 24px;
    line-height: 33.6px;
  }

  &__img {
    width: 53%;
    border-radius: 20px;
    img {
      max-height: 550px;
      border-radius: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .about-us {
    padding-top: 60px;
    padding-bottom: 60px;

    .container {
      padding: 0;
    }

    &__inner {
      align-items: flex-start;
    }

    &__text {
      padding: 0 20px;
      font-size: 20px;
      line-height: 28px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .about-us {
    &__inner {
      flex-direction: column-reverse;
      gap: 25px;
    }

    &__text {
      width: 100%;
    }
    &__img {
      width: 100%;
      img {
        border-radius: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .about-us {
    padding-top: 55px;
    padding-bottom: 40px;

    &__text {
      padding: 0 15px;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}
