//<---Color
$primaryGreen: #00f222;
$white: #ffffff;
$secondaryGray: #3c3c3c;
$secondaryOrange: #fe5d26;
$secondaryPurple: #9b5de5;
$secondaryYellow: #f1a208;
$secondaryBlue: #00a5e0;
$background: #3c3c3c;
$background2: #464646;
$grayWhite: rgba(255, 255, 255, 0.8);
$black: #1d1d1d;

//<---px
$container-width: 1268px;
