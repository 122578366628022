@import "../../scss/variables";

.stack-tech {
  padding-top: 70px;
  padding-bottom: 50px;

  .tech-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 42px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 19.57px;

    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .custom-checkbox + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }

    .custom-checkbox + label::before {
      content: "";
      display: inline-block;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $primaryGreen;
      border-radius: 4px;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      transition: all 0.15s linear;
    }

    .custom-checkbox:checked + label::before {
      border-color: $primaryGreen;
      background-color: $primaryGreen;
      background-image: url("../../assets/icon/check.svg");
    }
  }

  .tech {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 2%;

    &__items {
      width: 15%;
      padding: 1.6% 1% 3.5%;
      background-color: $background2;
      text-align: center;
      border-radius: 20px;
    }

    &__img {
      width: 64px;
      height: 64px;
      padding: 5%;
      margin: 0 auto;
    }

    &__title {
      margin-top: 14px;
      font-size: 22px;
      line-height: 26.4px;
    }
  }
}

@media only screen and (max-width: 1125px) {
  .stack-tech {
    .tech-filters {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .stack-tech {
    .tech-filters {
      font-size: 18px;
      gap: 3%;
      .custom-checkbox + label::before {
        width: 25px;
        height: 25px;
      }
      .filter {
        margin: 0 0 10px 0;
      }
    }

    .tech {
      row-gap: 15px;

      &__img {
        width: 51px;
        height: 51px;
      }

      &__title {
        margin-top: 11px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 930px) {
  .stack-tech {
    .tech-filters {
      font-size: 16px;
      justify-content: space-between;
      gap: 2%;

      .custom-checkbox + label::before {
        width: 22px;
        height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .stack-tech {
    .tech-filters {
      font-size: 16px;
      line-height: 19.57px;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 18px;

      .filter {
        width: 17%;
      }

      .custom-checkbox + label::before {
        width: 20px;
        height: 20px;
      }
    }

    .tech {
      row-gap: 12px;
      column-gap: 2.5%;

      &__items {
        width: 18%;
      }

      &__img {
        width: 40px;
        height: 40px;
      }

      &__title {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16.8px;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .stack-tech {
    .tech-filters {
      justify-content: center;
      flex-wrap: wrap;
      .filter {
        width: 120px;
      }
    }
    .tech {
      column-gap: 5%;
      row-gap: 17px;

      &__items {
        width: 30%;
      }
    }
  }
}
