@import "../../scss/variables";

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110vh;
  background-image: url("../../assets/banner/mainBanner.jpg");
  padding-top: 84px;
  background-size: cover;
  position: relative;
  max-width: 100%;
  background-repeat: no-repeat;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.581);
    z-index: 1;
  }

  &__inner {
    position: absolute;
    max-width: 1200px;
    top: 30%;
    z-index: 2;
  }

  &__text {
    .main-title {
      font-size: 56px;
      line-height: 61px;
      max-width: 680px;
      color: $white;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
    }

    .subtitle {
      font-size: 20px;
      line-height: 32px;
      max-width: 680px;
      color: $grayWhite;
      margin: 10px 0 40px;
      line-height: 132%;
    }

    .btn-more {
      font-weight: 500;
      font-size: 20px;
      color: $black;
      background-color: $primaryGreen;
      border-radius: 6px;
      padding: 12px 24px;
      z-index: 2;
    }
  }
}

.anchor {
  position: fixed;
  z-index: 99;
  right: 57px;
  bottom: 140px;
  width: 52px;
  height: 52px;
  cursor: pointer;
  animation: anchor 1s;
  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes anchor {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 1024px) {
  .banner {
    height: 100vh;
    background-size: cover;
    &__text {
      flex: 1.4;
      width: 520px;

      .main-title {
        font-size: 38px;
      }

      .subtitle {
        font-size: 18px;
        line-height: 32px;
        margin: 16px 0 30px;
      }
    }

    &__lottie {
      flex: 1;
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    height: 100vh;
    padding-top: 100px;
    .container {
      height: 100%;
    }
    &__inner {
      top: 35%;
    }

    &__text {
      flex: 0;

      .main-title {
        font-size: 28px;
        line-height: 35px;
        width: 480px;
      }

      .subtitle {
        font-size: 16px;
        line-height: 22px;
      }
      .btn-more {
        padding: 10px 20px;
      }
    }

    &__inner {
      height: 98%;
      flex-direction: column-reverse;
    }

    &__lottie {
      flex: 0;
      max-width: 60%;
    }
  }
}

@media only screen and (max-width: 550px) {
  .banner {
    height: 100vh;
    &__text {
      width: 95%;
      .main-title {
        font-size: 32px;
        line-height: 35px;
        width: auto;
      }

      .subtitle {
        margin: 15px 0 34px;
        width: auto;
      }
    }

    .btn-more {
      padding: 10px 20px;
      font-size: 14px;
    }

    &__inner {
      padding-bottom: 15px;
      gap: 0;
    }

    &__lottie {
      max-width: 100%;
    }
  }
}
