@import "../../scss/variables";

.modalWindowWrapper {
  visibility: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 15px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.7s;
  .modalWindow {
    min-width: 396px;
    visibility: hidden;
    display: flex;
    position: relative;
    padding: 24px 40px;
    background-color: #3c3c3c;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s, transform 0.7s, visibility 0.7s;

    .closeIcon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
    .textWrapper {
      margin-bottom: 16px;
      .modalWindowTitle {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 4px;
        width: 273px;
      }
      .subtitle {
        color: rgba(255, 255, 255, 0.7);
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        width: 273px;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      .formItem {
        .label {
          color: #fff;
          font-family: Commissioner;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 100%;
        }
        .star {
          color: red;
        }
        .input {
          display: block;
          width: 100%;
          height: 40px;
          padding: 12px 16px;
          margin-top: 4px;
          background-color: #ffffff00;
          border-radius: 4px;
          border: 1px solid #787878;
          color: #ffff;
          font-size: 14px;
          &:focus {
            border: 1px solid #00f222;
          }
        }
        .textaria {
          display: block;
          width: 100%;
          height: 150px;
          padding: 12px 16px;
          background-color: #ffffff00;
          border-radius: 4px;
          border: 1px solid #787878;
          color: #ffff;
          font-size: 14px;
          resize: none;
          margin-top: 4px;
          margin-bottom: 4px;
          &:focus {
            border: 1px solid #00f222;
          }
        }
        .counter {
          color: #8a8a8a;
          text-align: right;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .submitBtn {
        padding: 12px 24px;
        gap: 10px;
        border-radius: 4px;
        background: #00f222;
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.modalWindowWrapper.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s, visibility 0.7s;
  .modalWindow.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s, transform 0.7s, visibility 0.7s;
  }
}

@media only screen and (max-width: 425px) {
  .modalWindowWrapper .modalWindow {
    min-width: 90%;
    width: auto;
  }
}
