@import "src/scss/variables";

.service_card {
  max-width: 30%;
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 1.5% 59px;
  border-radius: 20px;
  text-align: center;
  background-color: $background2;
  transition: all 0.15s ease-in;

  &:hover {
    transform: scale(1.05);
  }

  &__img {
    max-width: 100px;
    max-height: 100px;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .service_card {
    max-width: 48%;
    &:hover {
      transform: scale(1);
    }

    &__title {
      font-size: 32px;
      line-height: 33.6px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .service_card {
    padding: 10px 1%;
    max-width: 100%;
    height: auto;
    background-color: transparent;

    &__title {
      margin: 26px 0;
      font-size: 20px;
      line-height: 24px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
}
