@import "../../scss/variables";
.header__info {
  .navKg {
    display: flex;
    flex-direction: column;
    gap: 11px;
    font-size: 16px;
  }
  .navNone {
    navKg {
      display: flex;
      flex-direction: column;
    }
  }
}
